@tailwind base;
@tailwind components;
@tailwind utilities;
html, body, #root, #root>div {
  height: 100%
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img{
  /* width:300px; */
}

ol,
ul {
  list-style: inherit;
  margin: inherit;
  padding: inherit;
}
button{
  border: none;
  color: white;
  padding: 14px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius:2px;
  cursor: pointer;
  background-color: #424242;
}

input{
  width: 30%;
  padding: 6px;
  border: 1px solid #cbcbcb;
  outline:none;
}


p {
  margin: 0 0 8px 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.container{
  height:100%;
  background:white;
  display: flex;
}

.channel-type{
  padding: 12px 16px;
  color: #6210CC;
  z-index: 3;
}
@media(max-width:767px){
  .channel-type{
    padding: 12px 18px; 
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}

@media(max-width:500px){
  .channel-type .explore-btn {
    background: #DDDFE5;
    color: #000;
    align-items: center;
    border: 1px solid #000;
  }
  .channel-type .create-btn {
    align-items: center;
  }
  .channel-type .explore-btn img,  .channel-type .create-btn img{
    margin-left: 10px;
  }
}

.group-card {
  width: fit-content;
  padding: 15px;
  margin-top: 20px;
  border: 1px solid white;
  border-radius: 10px;
}

.card {
  width: fit-content;
  padding: 15px;
  margin-left: 60px;
  border: 1px solid black;
  border-radius: 10px;
}

.card-name {
  font-weight: bold;
}

.channel-list {
  background: #F5F5F5;
  flex:1;
  border-right: 1px solid #cbcbcb;
  /* overflow-y: auto; */
  height: 100%;
}
.channel-list-ct {
  height: 60vh;
  overflow: auto;
}
@media(min-width:1025px){
  .channel-list-ct {
    overflow-y: scroll;
  }
  .channel-list-ct::-webkit-scrollbar {
    background: #F5F5F5; 
    width: 10px;
  }
  .channel-list-ct::-webkit-scrollbar-thumb {
    background-color: #cacaca;
  }
}
@media(max-width:767px){
  .channel-list-ct {
    height: 80%;
    overflow: auto;
    
  }
  .channel-list {
    flex: none;
    width: 100%;
  }
  .channel-list-ct {
    height: 85%;
    padding: 0px 0 32px;
  }
}
@media(max-width:375px){
  .channel-list-ct {
    height: 75%;
  }
}
@media(max-width:320px){
  .channel-list-ct {
    height: 70%;
  }
}

.channel-list #search-box{
  border-radius: 4px;
  padding: 6px 10px 6px 30px;
  width: 86%;
  margin-left: -16px;
}
@media (min-width:1025px){
  .channel-list #search-box{
    width: 88%;
    margin-right:8px;
    margin-left: 0px;
  }
 
}

@media(max-width:767px){
  .channel-list .search-header{
    margin: 20px auto 15px; 
  }
}
@media(max-width:1024px){
  .channel-list #search-box{
    width: 90%;
    margin-left: 0px;
  }
}

.channel-list-item{
  background: #FFFFFF;
  padding:8px 12px;
  border-top: 1px solid #ececec;
  display:flex;
  justify-content: space-between;
  align-items: center;
  min-height: 42px;
  color: #252525;
  width: 90%;
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  border-radius: 4px;
}
.channel-list-item.active{
  background: #d3d3d3;
}

.channel-list-item.active:hover{
  background: #d3d3d3;
}

.channel-list-item:hover{
  background:#f0f0f0;
}

.channel-list-item:hover .channel-icon{
  visibility: visible;
}
.channel-list-item:last-child{
  margin-bottom: 40px;
}
.sort-channels {
  width: 100%;
  font-size: 16px;
  padding: 7px;
  cursor: pointer;
}

.unarchive {
  transform: rotate(180deg);
}

.archived-channel {
  padding: 10px;
}

.last-message{
  font-weight:400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 230px;
  
}

.channel-list-item-name{
  font-weight: 600;
  cursor: pointer;
  overflow: hidden;
  max-width: 75ch;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex:1;
}

.channel-list-item-sup {
  margin: 0 0 0 5px;
  font-size: 10px;
}

.channel-icon{
  visibility:hidden;
  width: 20px;
}

.channel{
  background: white;
  flex: 3;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* z-index: 2; */
  /* overflow-y: scroll; */
  /* margin-bottom: 75px; */
}

@media(max-width:767px){
  .channel-list {
    z-index: 1;
    position: fixed !important;
    height: 100%;
  }
  /* .channel {
    flex: none;
    width: 100%;
    min-height: 100%;
    position: relative;
    z-index: 1;
    margin-left: 0%;
    position: absolute;
    margin-top: 80px;
  } */
  /* .chatbot-dashboard {
    
    z-index: 0;
  } */
}
.channel .mb-logo-section, .channel-list .mb-logo-section{
  display: none;
}
@media(max-width:500px){
  .channel .mb-logo-section, .channel-list .mb-logo-section{
    display: flex;
    background: #000;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    position: relative;
    width: 100vw;
  }
  /* .channel h1{
    padding: 20px 16px 0 10px;
  } */
  .channel-list {
    border-right: none;
  }
  .channel-list .mb-logo-section .mb-heading, .channel .mb-logo-section .mb-heading{
    /* font-size: 22px; */
    padding: 1.5px 0;
  }
}
.channel-fixed-header {
  margin-top: 160px;
}


.how-to-use ul li{
  list-style: none;
}
.thread {
  max-width: 35vw;
}

.thread, .thread-input {
  border-left: 1px solid lightgray;
}

/* .underline {
  width: 100%;
  border-bottom: 1px solid lightgray;
} */

.threads {
  max-width: 35vw;
}

.threads, .threads-input {
  border-left: 1px solid lightgray;
}

/* .underline {
  width: 100%;
  border-bottom: 1px solid lightgray;
} */

.channel-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leave-channel{
  /* margin-left: 12px; */
  padding: 8px;
}

.freeze-channel {
  margin-right: 12px;
}

.freeze-channel input {
  width: auto;
}

.message-list{
  /* height: 100%; */
  /* display: flex; */
  flex-direction: column;
  overflow-y: scroll;
  height: 90%;
  padding-bottom: 0px;
  
}

.message-list .message-item img{
  max-width: 300px;
}

@media(max-width:767px){
  .message-list .message-item img{
    max-width:100%;
  }
}

@media(max-width:767px){
  .message-list{
    height: 90%;
    padding-top: 75px;
  }
  /* .message-list {
    padding-bottom: 100px;
    padding-top: 65px;
  } */
}

.message-item{
  margin: 12px;
  display:flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  /* white-space: pre-line; */
  word-wrap: break-word;
}

.message.message-from-you{
  margin-left: auto;
  background: #cfcfcf;
  flex-direction: row;
  margin-right: 12px;
}

.message-item.message-from-you{
  flex-direction: row;
}

.oc-message, .oc-message-og-tags{
  padding:12px;
  display:flex;
}

.oc-message-og-tags {
  flex-direction: column;
  max-width: 50%;
  padding: 5px;
  margin-left: 7vw;
  margin-top: -10px;
  box-sizing: border-box;
  border: 1px solid black;
}

.oc-message, .message-og-tags {
  padding:12px 0px;
  display:flex;
}

.message-og-tags {
  flex-direction: column;
  padding: 5px;
  box-sizing: border-box;
}

.og-tags-title {
  margin: 0 0 10px;
}

.og-tags-description, .og-tags-url, .metadata-modal-list {
  margin: 0 0 5px;
}

.og-tags-img, .show-users-status-btn, .members-invite-btn {
  width: 100%;
}

.oc-admin-message {
  background: rgba(255, 0, 0, 0.05);
}

.report-notification {
  background: rgba(0, 255, 0, 0.5);
  color: white;
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin: 5px 0;
  padding: 5px;
}

.message{
  border-radius: 8px;
  width:70%;
  background: #F5F5F5;
  color:#252525;
  padding: 10px 15px;
  margin-left: 12px;
  overflow-x: auto;
  width: auto;
  max-width: 90%;
}
@media(max-width:767px){ 
  .message{
    padding: 15px;
  }
}
@media(max-width:767px){ 
  .message{
    width: 78%;
    padding: 8px;
  }
} 

.admin-message {
  background: rgba(255, 0, 0, 0.05);
  width: 100%;
  color: black;
  margin-left: 0;
}

.message-info{
  margin-bottom:4px;
  display: flex;
  justify-content: space-between;
  line-height: 24px;
}

.message.message-from-you a, .message-item .message a {
  color: #0000ff;
}

.message-item .message ul {
  margin:10px 25px;
}

.message-item .message li {
  list-style: number;
}


.message-icon{
  visibility:hidden;
  width: 18px;
  /* filter: brightness(0) invert(1); */
}
@media(max-width:767px){
  .message-icon {
    width: 14px;
  }
}

.oc-message-icon, .oc-channel-list-icon{
  visibility:hidden;
  width: 14px;
}


.message:hover .message-icon, .channel-list-item:hover .oc-channel-list-icon, .member-item:hover .message-icon, .double_tick-icon, .member-item-wrapper .message-icon {
  visibility: visible;
}

.double_tick-icon-read {
  filter: none;
}

.mute-button {
  margin-top: 3px;
}

.member-item .message-icon {
  filter: none;
}

.oc-document-message, .message-user-info, .react-button-wrapper, .reactions-list, .metadata-modal-list, .create-channel-wrapper {
  display: flex;
}

.reactions-list {
  list-style: none;
  margin: 0;
  padding-left: 0;
  background: white;
  border-radius: 8px;
}

.reactions-list li {
  margin-right: 2px;
  padding: 4px;
}

.react-button-wrapper {
  margin: 5px 5px 0 0;
  width: 100%;
  justify-content: flex-end;
}

.react-button-img {
  width: 20px;
  filter: none;
}

.reactions {
  margin: 5px 0 0;
}

.reactions-item {
  cursor: pointer;
  margin-right: 5px;
}

.reactions-item-inner {
  font-size: 10px;
}

.oc-document-message-icon {
  width: 24px;
}

.oc-message:hover .oc-message-icon, .message-input .oc-message-icon, .metadata-modal-list .oc-message-icon {
  visibility: visible;
}

.oc-message-sender-name{
  color:#6210CC;
  margin-left:4px;
  font-weight: 600;
  margin-right:4px;
}

.message-sender-name{
  font-weight: 600;
  margin-right:4px;
}

.channel-header{
  padding: 17px 12px 15px;
  font-size: 24px;
  font-weight: 800;
  position: sticky;
  top: 0;
  background: #fff;
}
@media(max-width:767px){
  .channel-header {
    position: fixed;
    top: 0;
    width: 100%;
  }
}

/* code by ayaz */
@media(max-width:600px){
  .channel-header .channel-name, .channel-header .header-name-section{
    flex: 1;
  }
  
  .channel-header .header-back-arrow{
    width: 48px;
    height: auto;
  }
  .channel-header .header-right-icons{
    margin-left: 8px;
  }
}

.channel-header-wrapper {
  position: fixed;
  width: 100%;
  top: 0;
}

.members-list{
  border-left: 1px solid #cbcbcb;
  padding: 12px;
  width: 250px;
  /* margin-top: 80px; */
  background-color: #F5F5F5;
  overflow-y: scroll;
  padding-bottom: 100px;
}
@media(max-width:767px){
  .members-list {
    position: fixed;
    z-index: 5;
    width: 100%;
    background-color: #F5F5F5;
    transition: 1s all;
  }
}
@media(min-width:768px) and (max-width:920px){
  .members-list {
    width: 210px;
  }
}

.member-item{
  /* padding: 12px 8px; */
  padding: 12px 25px;
  cursor: pointer;
  font-weight: 600;
  /* border-bottom: 1px solid #cfcfcf; */
  display:flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
  position: relative;
}

.member-item.online::before,.member-item.offline::before{
  content: '';
  background: green;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
}

.member-item.offline::before{
  background: red;
}

/* .member-item p{
  position: relative;
}

.member-item .online{
  right: -20px;
  background: green;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: absolute;
  top: -3px;
} */

.member-item-wrapper {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}

.member-item:hover{
  background:#f0f0f0;
}

.member-item-name{
  margin-left:12px;
}

.member-list-btn {
  margin: 0 0 5px;
}

.member-selected{
  color: #62EEAB;
}

.operators-list {
  padding: 20px 0;
  font-weight: bold;
}

.operator-item {
  padding: 8px 0px 0px 0px;
  cursor: pointer;
  font-weight: 600;
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 4px;
  display:flex;
  align-items: center;
  justify-content: space-between;
}

.operator-item:hover {
  background:#f0f0f0;
}

.message-input{
  /* position: fixed; */
  bottom: 0px;
  display: flex;
  border-top: 1px solid #cbcbcb;
  width: 100%;
  padding: 12px;
  background: white;
}
@media (min-width:767px) and (max-width:768px){
  .message-input {
    padding-right: 35px;
  }
}

.overlay{
  position: fixed;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.7);
  z-index: 70;
}

.overlay-content{
  overflow-y: auto;
  max-height: 100%;
  padding:22px;
  opacity: 1;
  background: white;
  border: 1px solid #cbcbcb;
  width: 60%;
}
@media(max-width:1024px){
  .overlay-content {
    width: 60%;
  }
}
@media(max-width:767px){
  .overlay-content {
    width: 90%;
    padding: 20px;
  }
  .install-icon{
    width:18px;
    display: inline;
    margin: 0 5px;
  }
  .modal-text p{
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.custom-type-messages {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.custom-type-messages-content {
  overflow-y: scroll;
  max-height: 400px;
  padding:20px;
  opacity: 1;
  background: white;
  border: 1px solid #cbcbcb;
  width: 40%;
}

.custom-type-button {
  margin: 12px 0 0 12px;
}

.form-input{
  margin-top: 12px;
  margin-bottom: 12px;
  padding:18px;
  width:100%;
  border-radius: 2px;
}

.form-button{
  margin-right:4px;
}

.user-submit-button{
  background-color: #6210CC;
}

.create-channel-wrapper {
  align-items: baseline;
}

.channel-create-button{
  background-color: #62EEAB;
  width: 100%;
}

.create-channel-checkbox {
  width: auto;
  margin: 0 5px 0 10px;
}

.select-custom-type {
  padding: 12px;
}

.select-custom-type select {
  width: 100%;
  font-size: 16px;
  padding-top: 12px;
  outline: none;
  border: none;
  cursor: pointer;
}

.send-message-button{
  /* border-radius: 0px 4px 4px 0px; */
  margin-right:8px;
}

.control-button {
  background: inherit;
  padding:4px;
  margin-right:1px;
}

.error{
  background:rgb(26, 22, 22);
  display:flex;
  padding:24px;
  width:100%;
}

.file-upload-label, .user-profile-image-upload-label, .number-of-undelivered-members-btn {
  cursor: pointer;
}

.file-upload-label img{
  width: 32px;
  max-width: 32px;
  vertical-align: middle;
  background: #d9d9d9;
  padding: 5px 5px;
  border-radius: 50%;
}

.message-type-add {
  cursor: pointer;
  padding: 12px;
}

.message-type-label {
  margin: 0 10px;
}

.message-type-select {
  font-size: 16px;
  padding: 7px;
  outline: none;
  cursor: pointer;
}

.profile-image-fallback{
    width: 50px;
    background: #e9e9e9;
    height: 50px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-image{
  width: 50px;
  height: 50px;
  border-radius: 25px;
  object-fit: cover;
  border: 1px solid #eee;
}

.typing-indicator {
  position: fixed;
  bottom:80px;
  background-color: #fff;
  opacity: 0.8;
  margin-left: 20px;
  margin-top:20px;
  font-weight: 500;
}

.display-none {
  display: none;
}

.message-input-column {
  flex-direction: column;
}

.freeze-notification {
  position: sticky;
  top:0;
  width: 100%;
  line-height: 30px;
  opacity: 70%; 
  background-color: rgb(173,201,255);
  text-align: center;
}

.frozen-icon {
  width: 20px;
  margin-right: 10px;
}
/* 
.underline {
  width: 100%;
  border-bottom: 1px solid lightgray;
} */

.user-copied-message {
  position: fixed;
  right: 20px;
}

.image-size-error {
  width: 100%;
  background: rgba(255, 0, 0, 0.2);
  padding: 5px;
  margin: 5px 0;
  text-align: center;
}

.channel-header-wrapper {
  display: flex;
  justify-content: space-between;
}

.create-metadata-conteiner {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 12px;
}

.create-metadata-title {
  margin: 0;
  padding: 5px;
  font-size: 16px;
}

.create-metadata-btn {
  padding: 5px 10px;
}

.metadata-modal-input {
  display: flex;
  margin: 0 0 10px;
}

.choise-report-obj {
  cursor: pointer;
  color: #6210CC;
  text-decoration: underline;
}

.number-of-undelivered-members {
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
}

.number-of-undelivered-message-btn:hover::after {
  content: attr(data-title);
  position: absolute;
  border: 1px solid gray;
  background-color: darkgray;
  border-radius: 5px;
  padding: 2px 4px;
  font-size: 10px;
}

.banned-muted-users-list {
  margin-top: 20px;
}

.show-users-status-btn {
  display: block;
  margin-top: 5px;
}

.user-online-status {
  margin: 0 0 0 5px;
}
.user-profile-title {
  margin-top: 0;
}

.user-profile-image-wrapper, .user-profile-nickname-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.user-profile-nickname-wrapper {
  margin: 0 0 10px;
}

.user-profile-image {
  border-radius: 50%;
  padding: 5px;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.user-profile-nickname-input {
  width: auto;
}

.user-profile-nickname-button {
  border: none;
  background: transparent;
  color: black;
}

.user-profile-image-upload-label, .user-profile-nickname-button {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  padding: 5px;
}

.number-of-undelivered-members {
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
}

.number-of-undelivered-message-btn:hover::after {
  content: attr(data-title);
  position: absolute;
  border: 1px solid gray;
  background-color: darkgray;
  border-radius: 5px;
  padding: 2px 4px;
  font-size: 10px;
}

.do-not-disturb-wrapper, .do-not-disturb-start-wrapper, .do-not-disturb-end-wrapper, .notifications-snooze-wrapper, .notifications-snooze-input-wrapper {
  display: flex;
}

.do-not-disturb-wrapper, .notifications-snooze-wrapper {
  flex-direction: column;
}

.do-not-disturb-wrapper, .do-not-disturb-start-wrapper, .do-not-disturb-end-wrapper, .notifications-snooze-wrapper, .notifications-snooze-input-wrapper {
  margin-bottom: 10px;
}

.notifications-overlay-content {
  padding: 40px;
  width: 50%;
  max-height: 500px;
}

.notification-modal-label, .notification-modal-input {
  margin-right: 10px;
}

.notification-modal-input {
  width: 40%;
}

.open-poll-btn {
  padding: 5px;
  background: transparent;
  color: black;
  margin-left: 2px;
}

.create-poll-modal, .create-poll-modal_inputs {
  display: flex;
  flex-direction: column;
}

.create-poll-modal {
  position: relative;
}

.create-poll-modal input {
  padding: 14px 32px 14px 6px;
}

.create-poll-modal_inputs {
  justify-content: space-between;
  margin-bottom: 10px;
}

.create-poll-modal .input_wrapper {
  margin-bottom: 5px;
}

.create-poll-modal .input_wrapper input[type="text"] {
  width: 60%;
}

.create-poll-modal h3 {
  text-align: center;
}

.option-add_btn {
  border: 1px solid #cbcbcb;
  border-radius: 0 4px 4px 0;
  font-size: 14px;
}

.poll-close_btn {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
}

.poll-create_btn {
  margin: 0 auto;
}

.poll-options {
  width: 100%;
}

.options_item {
  padding: 0 4px;
  margin-right: 4px;
  background: #cbcbcb;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.oc-members-list {
  margin-bottom: 80px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.scheduled-messages-list-modal {
  position: relative;
}

.close-button {
  background: none;
  padding: 0;
  position: absolute;
  top: 24px;
  right: 24px;
}

.update-input {
  display: flex;
}

.update-input input {
  width: 50%;
}

.scheduled-message-header {
  font-weight: 800;
  margin-bottom: 30px;
}

.schedule-message {
  margin-top: 20px;
}

.cancel-button {
  margin-right: 10px;
}

.scheduled-messages-settings-modal {
  z-index: 2;
}

.settings-header {
  margin-bottom: 30px;
  font-weight: 800;
}

.schedule-message-error {
  color: red;
  margin-bottom: 20px;
}

[data-tooltip] {
  position: relative;
}

[data-tooltip]::after {
  content: attr(data-tooltip);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: 1s;
  pointer-events: none;
  padding: 2px;
  background-color: #424242;
  border-radius: 4px;
  font-size: 10px;
  width: max-content;
  z-index: 100;
}

[data-tooltip]:hover::after {
  opacity: 1;
  top: -1.5em;
}

.option-icon {
  visibility:hidden;
  width: 14px;
  filter: brightness(0) invert(1);
}

.option_wrapper:hover .option-icon {
  visibility: visible;
}

.create-poll-modal .option input[type="text"] {
  width: 55%;
}

.add-new-option {
  background: white;
  border-radius: 4px;
  color: black;
  padding: 5px;
  font-size: 12px;
  margin-top: 10px;
}

.poll-message {
  position: relative;
}

.poll-status {
  position: absolute;
  bottom: 24px;
  right: 24px;
  font-size: 10px;
  padding: 5px;
}

.option_wrapper label, .add-new-option {
  margin-right: 10px;
}

.poll-status_wrapper {
  width: 100%;
  min-height: 25px;
}

.vote {
  margin-right: 5px;
  padding: 5px;
  font-size: 10px;
  background: white;
  color: black;
  border-radius: 50%;
}

.pin-checkbox {
  display: inline-block;
}

.message-img {
  max-width: 100%;
}

.oc-message-container{
  white-space:pre-line;
}
.bgcolor-dark-blue{
  background: #2C2D98;
}
.groupChatWidow{
  flex: 3 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.chat-input-box {
  border: none !important;
  padding: 0 10px;
  -webkit-appearance:none;
  min-height: 48px;
  height: auto;
  max-height: 180px;
  padding: 10px;
  /* overflow-y: auto !important; */
}
@media(max-width:767px){
  .top-nav {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .chat-input-box {
    max-height: 120px;
  }
}

.fileupload-view {
  
}
.kb-data-box {
  width: 100%;
  flex: 1;
}

.kb-modal-data-title {
  margin-bottom: 10px;
}
.kb-data-title h6 {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
}
/* .kb-file-upload {
  margin-bottom: 20px;
} */
.kb-attach-box {
  min-height: 40px;
  margin: 0;
  padding-bottom: 15px;
}
.file-upload-box {
  /* background-color: #E3E3E3; */
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8194aa;
  font-weight: 400;
  font-size: 15px;
  max-width: fit-content;
  padding: 6px 0 8px;
  padding-left: 2px;
}
.file-upload-box .file-upload-input {
  position: absolute;
  /* width: 100%; */
  /* height: 100%; */
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.file-link{
  margin-left: 0px;
}
.file-link img {
  width: 23px;
}
.file-validation-msg {
  font-size: 16px;
}
@media(max-width:767px){
  .file-link {
    font-size: 21px;
  }
  .file-link img {
    /* width: 24px; */
  }
  .file-validation-msg {
    font-size: 12px;
  }
}
@media(max-width:320px){
  .file-validation-msg {
    font-size: 10px;
  }
}
.file-atc-box {
  display: flex;
  align-items: center;
  /* margin-bottom: 15px; */
}
.file-image {
  /* width: 130px;
  min-width: 130px;
  height: 85px;
  min-height: 85px; */
  /* width: 130px; */
  /* height: 85px; */
  background-size: cover;
  border-radius: 5px;
  margin-right: 15px;
  background-color: #eaecf1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #475f7b;
  padding: 3px;
}
.file-image img{
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}
.file-detail {
  flex: 1;
  width: calc(100% - 210px);
}
.file-detail a {
  word-break: break-all;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  /* margin-bottom: 8px; */
}
.file-detail p {
  font-size: 12px;
  color: #8194aa;
  line-height: initial;
  font-weight: 400;
  margin-bottom: 8px;
}
.file-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}
.file-action-btn {
  font-size: 12px;
  color: #8194aa;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  margin-right: 15px;
  cursor: pointer;
}
.file-action-btn:hover {
  color: #3d546f;
  text-decoration: underline;
}
.file-atc-box:last-child{
  margin-bottom: 0;
}
.brainstrom-logo {
  width: 36%;
}
@media(max-width:767px){
  .brainstrom-logo { 
    width: 35%;
  }
}
.close-icon {
  padding: 0 7px;
}
.file-item .file-name {
  overflow-wrap: anywhere;
  max-width: 80%;
}
.file-item .delete-file {
  max-width: 40px;
}
.loader-ct {
  width: 100%;
  height: 100%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255, 0.7);
}
.files-list {
  /* min-height: 40px; */
  position: relative;
  margin-top: 0px;
}

.form-appearance{
  -webkit-appearance:none;
}
.flexStrom{
  height: 100%;
  flex-wrap: wrap;
  overflow: hidden;
}
.chnamesidebar{
  max-width: 300px;
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.list-decimal::marker{
  font-weight: bold;
  color: #000;
}
.swiper-pagination-bullet-active{
  background:#000 !important;
}

@media(max-width:767px){
.message-input{
  padding: 3px;
}
}
.my-custom-pagination-div{
  padding-bottom: 1.5rem;
}
.videoYoutube{
  position:relative;
  top:0;
  left:0;
  width:100%;
  height:100%;
}

@media(max-width:767px){
  .videoYoutube{
   display: none;
  }
}
.suggestion-box {
  bottom: 68px;
  min-width: 230px;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 6px;
}
@media(max-width:767px){
  .suggestion-box {
    bottom: 60px;
  }
}
.suggestion-box ul li {
  line-height: 30px;
}
.chat-input-box__suggestions {
    left: 0px !important;
    top: auto !important;
    bottom: 48px !important;
    /* padding: 10px; */
    border: 1px solid gray;
    border-radius: 5px;
    min-width: 230px !important;
    background: #fff;
    z-index: 222;
}
.chat-input-box__suggestions ul li {
  line-height: 30px;
  padding: 6px 10px;
}
.chat-input-box__suggestions ul li.chat-input-box__suggestions__item--focused {
  background-color: #2C2D98;
  color: #fff;
}
.chat-input-box__input {
  padding: 10px !important;
  padding: 11px 10px 10px !important;
}
@media(max-width:767px){
  .chat-input-box__input {
    /* line-height: 30px; */
    padding: 10px 10px 10px !important;
  }
}
@media(min-width:768px) and (max-width:912px){
  .chat-input-box__input {
    /* line-height: 30px; */
    padding: 10px 10px 10px !important;
  }
}
#storm_msg {
  overflow-y: auto !important;
  min-height: 48px;
  height: auto;
  max-height: 200px;
  outline: none;
}
.message-input-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}
.message-input-buttons #send_message {
  height: 48px;
}
@media(max-width:767px){
  .message-input-buttons #send_message {
      height: auto;
      width: max-content;
      padding: 0;
      border-radius: 22px;
      margin: auto;
  }
  .message-input-buttons #send_message span img {
    width: 32px;
    /* transform: rotate(45deg); */
    padding: 8px 6px 8px 10px;
  }
}
::-webkit-input-placeholder,::placeholder {
  position:relative;
  white-space: pre-line;
  word-break: break-all;
  overflow-wrap: normal;
  word-break: break-word;
}
#send_message[disabled]{
  background-color: #777777;
}
#send_message[disabled] span img {
  opacity: 0.4;
}
@media(max-width:912px){
  #storm_msg::placeholder { /* Chrome/Opera/Safari */
    line-height: 20px !important;
    /* margin-top: 0px; */
    font-size: 14px;
    position: absolute;
    top: 4px;
  } 
}

thead{
  background: #dbdbdb;
}
table,
tr,
td,
th {
  border: 1px solid #555;
  padding: 10px;
  margin: 10px 0;
  text-align: left;
}
code{
  white-space: pre-line;
}
.channel-card  {
  max-height: 240px;
  width: 46%;
  /* height: fit-content; */
  /* height: 251px; */
}
.channel-card .content-ct {
  width: 100%;
}
.channel-card .content-ct a{
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
}
.channel-card .description {
  /* display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden; */
  min-height: 40px;
  min-height: 40px;
  font-weight: 500;
  font-size: 15px;
  line-height: 142.52%;
  letter-spacing: -0.02em;
  color: #252525;
}

.channel-card .description{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.main-txt {
  max-width: 600px;
}

@media(max-width:767px){
  .channel-card  {
    width: 45%;
    /* margin: 10px auto; */
  }
  .main-txt {
    max-width: 100%;
  }
}

@media(max-width:500px){
  .channel-card  {
    width: 100% !important;
    /* margin: 10px auto; */
  }
}
@media(max-width:320px){
  .channel-card  {
    width: 44%;
  }
}
.channel-card .img-innet-ct {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
}
.channel-card .img-innet-ct img {
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
@media(max-width:767px){
  .channel-card .img-innet-ct {
    width: 32px;
    height: 32px;
  }
  .channel-card .content-ct .start-convr-link {
    font-size: 14px;
    /* padding: 5px; */
  }
}
@media(min-width:768px) and (max-width:912px) {
  .channel-card {
    width: 100%;
  }
}
/* @media(min-width:913px) and (max-width:1024px) {
  .channel-card {
    width: 30%;
  }
} */
/* .channel-card .top-sec h5 {
  word-break: break-word;display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  font-weight: 700;
  font-size: 23.3684px;
  line-height: 28px;
  text-align: left;
} */
.bot-lib .tabs .tabitem__title{
  width: max-content;
}
.bot-lib .tabs-ct {
  width: 100%;
  overflow-x: auto;
  padding: 0px 0 20px 0;
  max-width: 70vw;
}
@media(max-width:767px){
  .bot-lib .tabs-ct {
      max-width: 95vw;
  }
}
.bot-lib .bot-content-ct {
  /* padding: 20px 0; */
  height: 90vh;
  overflow-y: auto;
}

.bot-lib .bot-content-ct.single-bot{
  max-height: 90vh;
  height: 100%;
}

@media(max-width:1366px){
  .bot-lib .bot-content-ct {
    height: 83vh;
  }
}

@media(max-width:500px){
  .bot-lib .bot-content-ct .sub-heading {
    padding: 0;
    color: #252525;
    margin-bottom: 25px;
  }
  .channel .wrapper{
    padding-top: 0;
  }
}
.tagTitle{
  font-size: 12px;
}
.bot-lib .tabs-ct::-webkit-scrollbar {
  height: 5px;
}

.bot-lib .tabs-ct::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
}

.bot-lib .tabs-ct::-webkit-scrollbar-thumb {
  height: 5px;
  background-color: #b3b0ad;
}

.bot-lib .tabs-ct::-webkit-scrollbar-thumb:hover {
  background-color: #b6b6b6;
}

.bot-lib .tabs-ct::-webkit-scrollbar:vertical {
  display: none;
}

.bot-lib .bot-content-ct::-webkit-scrollbar {
  width: 7px;
}

.bot-lib .bot-content-ct::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px grey;
}

.bot-lib .bot-content-ct::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #b3b0ad;
}

.bot-lib .bot-content-ct::-webkit-scrollbar-thumb:hover {
  background-color: #b6b6b6;
}
.hourglass::after {
  content: '⏳';
  --emoji: '⌛️';
  animation-name: twoFrames;
  animation-duration: 1.2s;
}
.emoji {
  font-size: calc(100px + 4vw);
  min-width: 1.4em;
  margin: 0.3em 0.4em;
  text-align: center;
}

.emoji::after {
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes twoFrames {
  50% {
      content: var(--emoji);
  }
}

.message  blockquote {
  padding: 15px;
  background: white;
  margin: 15px;
}
.my-custom-pagination-div {
  padding-bottom: 0px !important;
}
#fileuplod_popup_wrapper h3{
  margin-top: 0px;
}
#fileuplod_popup_wrapper input {
  border: none;
  width: 80%;
  font-size: 14px;
  color: #838282;
  /* visibility: hidden; */
  height: 45px;
}
.input-file-ct {
  width: 80%;
  /* position: relative; */
  border-radius: 5px;
  /* height: 42px; */
}
#fileuplod_popup_wrapper .overlay-content {
  width: 45%;
}
#fileuplod_popup_wrapper textarea {
  resize: none;
}
@media(min-width:768px) and (max-width:1024px){
  #fileuplod_popup_wrapper .overlay-content {
    width: 60%;
  }
}
@media(max-width:767px){
  #fileuplod_popup_wrapper .overlay-content {
    width: 90%;
  }
}
.choose-file-ct {
  /* position: relative; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* background: #f6f6f6;
 
  border-radius: 5px; */
}
@media(max-width:767px){
  .choose-file-ct {
    align-items: flex-start;
    flex-direction: column;
  }
  .choose-file-ct button {
    margin-top: 15px;
  }
}
.choose-file-ct.invisible {
  width: 80%;
}
.choose-file-ct button {
  /* position: absolute;
  z-index: 111;
  right: 8px; */
}
.choose-file-label {
  background: #fff;
    /* border: 2px dashed #bbbbca; */
    border-radius: 5px;
    color: #2C2D98;
    cursor: pointer;
    display: inline-block;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 1rem;
    outline: none;
    padding: 7px 10px;
    transition: all 0.3s;
    vertical-align: middle;
    position: absolute;
    z-index: 11;
    /* top: 5px; */
    width: 75%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: row;
    background: #f6f6f6;
    height: 42px;
    border: 1px solid #dbdbdb;
}
@media(max-width:767px){
  .choose-file-label {
    width: 100%;
  }
  .input-file-ct {
    width: 100%;
    position: relative;
  }
  .files-list {
    width: 100%;
  }
}
@media(min-width:768px) and (max-width:1024px){
  .choose-file-label {
    width: 80%;
  }
  .input-file-ct {
    width: 100%;
    position: relative;
  }
  .files-list { 
    width: 75%;
  }
}
.msglistpopup-filelist {
  margin-top: 0px !important;
  height: 42px;
}
.msglistpopup-filelist li {
  margin: 0 !important;
}
.file-name-link {
  max-width: 90% !important;
}
.loader-ct2 {
  max-width: 150px;
  width: 100%;
  height: inherit;
}
.uploadloader {
  display: block;
  content: "";
  position: relative;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 2px solid transparent;
  border-top-color: #ffffff;
  border-right-color: #ffffff;
  /* border-bottom-color: #ffffff; */
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
.upload-file-error {
  margin-top: 4px !important;
  display: block;
}

.chat-typing-indicator {
  background-color: #F5F5F5;
  width: auto;
  border-radius: 50px;
  padding: 8px;
  display: table;
  margin: 20px 0 25px 20px;
  position: relative;
  -webkit-animation: 2s bulge infinite ease-out;
          animation: 2s bulge infinite ease-out;
}
.chat-typing-indicator:before, .chat-typing-indicator:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: -2px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #F5F5F5;
}
.chat-typing-indicator:after {
  height: 7px;
  width: 7px;
  left: -10px;
  bottom: -10px;
}
.chat-typing-indicator span {
  height: 8px;
  width: 8px;
  float: left;
  margin: 0 3px;
  background-color: #2C2D98;
  display: block;
  border-radius: 50%;
  opacity: 0.2;
}
.chat-typing-indicator span:nth-of-type(1) {
  -webkit-animation: 1s blink infinite 0.3333s;
          animation: 1s blink infinite 0.3333s;
}
.chat-typing-indicator span:nth-of-type(2) {
  -webkit-animation: 1s blink infinite 0.6666s;
          animation: 1s blink infinite 0.6666s;
}
.chat-typing-indicator span:nth-of-type(3) {
  -webkit-animation: 1s blink infinite 0.9999s;
          animation: 1s blink infinite 0.9999s;
}

@-webkit-keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes bulge {
  50% {
    transform: scale(1.05);
  }
}
@keyframes bulge {
  50% {
    transform: scale(1.05);
  }
}

.chat-loading-ct .profile-image-ct {
    display: none;
}
.chat-loading-ct .profile-image-ct:first-child {
  display: flex;
}

.black-btn {
  background-color: #000;
}
.black_text {
  color: #000;
}
.notiflix-confirm-content h5 {
  color: #000 !important;
}
.notiflix-confirm-content .nx-confirm-button-ok{
  background-color: #000 !important;
}
.contentWrapper-0-3-106 {
  background: #000 !important;
}
#NotiflixConfirmWrap .notiflix-confirm-buttons{
  display: flex !important;
  flex-direction: row-reverse !important;
 }
 #NotiflixConfirmWrap .notiflix-confirm-buttons #NXConfirmButtonOk{
  float: right !important;
  margin: 0 0 0 2% !important;
 }
 #NotiflixConfirmWrap .notiflix-confirm-buttons #NXConfirmButtonCancel{
  float: left !important;
  margin: 0 2% 0 0 !important;
 }
